import React, { useContext, useRef, useState } from "react";
import { Modal } from "antd";
import applicationContext from "../context/applicationContext";
import { useNavigate } from "react-router-dom";
import { QrReader } from "react-qr-reader";

export default function QRCode() {
  const { postPassaporte, setScanned, login, postVisitante } = useContext(applicationContext);
  const history = useNavigate();
  const [startScan, setStartScan] = useState(true);

  const handleScan = (data) => {
    if (startScan && data?.text?.length > 0) {
      setScanned(true);
      setStartScan(false); // Altera o estado para parar o escaneamento após a primeira leitura bem-sucedida
      history("/");
      login?.tipo === "visitante" && postPassaporte(data.text);
      login?.tipo === "expositor" && postVisitante(data.text);

      login?.tipo === "visitante" &&
        Modal.success({
          title: "Parabéns",
          content: "Passaporte carimbado!",
        });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      {/* Renderiza o scanner QR somente quando startScan for true */}
      {startScan && (
        <QrReader
          onResult={handleScan}
          onError={handleError}
          scanDelay={1000} // Intervalo de escaneamento em milissegundos
          style={{ width: "100%", height: "100vh" }}
          constraints={{ facingMode: "environment" }}
        />
      )}
      {/* Botão de voltar */}
      <div
        onClick={() => {
          history("/");
          setStartScan(false); // Define startScan como false para evitar renderização contínua do scanner
        }}
        style={{ color: "#0091ff", fontSize: 30, textAlign: "center", width: "100%", position: "fixed", bottom: "4rem" }}
      >
        Voltar
      </div>
    </div>
  );
}
