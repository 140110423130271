import React, { useContext, useState, useEffect } from "react";
import { Button, Divider, Drawer, List } from "antd";
import {
  BarChartOutlined,
  BarcodeOutlined,
  DeleteOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  SafetyOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import applicationContext from "../context/applicationContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Row, Col, Image } from "antd";
import background from "../assets/images/background.jpeg";

export default function Home() {
  const { expositores, passaporte, getPassaporte, setScanned, sair, login, visitante } = useContext(applicationContext);
  const history = useNavigate();
  const [hasPermission, setHasPermission] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Isso rolará para o topo da página
  }, []);

  useEffect(() => {
    // Verifica se o CPF está presente no localStorage
    const cpf = localStorage.getItem("cpf");
    if (!cpf) {
      // Se não estiver presente, redireciona para a rota de login
      history("/login");
    } else {
      // Se estiver presente, continua com a lógica normal do componente
      getPassaporte();
    }
  }, []);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      // Coloque a lógica necessária aqui para solicitar permissões, se necessário
    };

    getBarCodeScannerPermissions();
  }, []);

  const menuItems = [
    { title: "Início", icon: <HomeOutlined />, onClick: () => history("/") },
    {
      title: "Ler QRCode",
      icon: <BarcodeOutlined />,
      onClick: () => {
        setScanned(false);
        history("/qrcode");
      },
    },
    {
      title: "Sair",
      icon: <LogoutOutlined />,
      onClick: () => {
        sair();
        history("/login");
      },
    }, // Implementar a lógica de sair
    { title: "Política de Privacidade", icon: <SafetyOutlined />, onClick: () => history("/politica-de-privacidade") },
    { title: "Excluir Conta", icon: <DeleteOutlined />, onClick: () => history("/excluir-conta") }, // Implementar a lógica de excluir conta
  ];

  return (
    <div
      style={{
        backgroundColor: "#000",
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "4.8rem",
          background: "#171717",
          position: "fixed",
          top: "0",
          zIndex: 999,
          opacity: 0.9,
        }}
      >
        <Button
          size="large"
          type="text"
          style={{ position: "fixed", top: "1rem", left: "2rem" }}
          icon={<MenuOutlined style={{ color: "#ea3717", fontSize: "2rem" }} />}
          onClick={() => setDrawerVisible(true)}
        ></Button>
        <Drawer title="Menu" placement="left" closable={true} onClose={() => setDrawerVisible(false)} visible={drawerVisible}>
          {/* <List
            dataSource={menuItems}
            renderItem={(item) => (
              <List.Item key={item.title} onClick={item.onClick}>
                {item.icon}
                <span style={{ marginLeft: 10 }}>{item.title}</span>
              </List.Item>
            )}
          /> */}
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
            <div style={{}}>
              <div
                onClick={() => {
                  history("/");
                }}
              >
                <p
                  style={{
                    padding: "1rem 1rem",
                    color: "#ea3717",
                  }}
                >
                  Início
                </p>
              </div>

              <p
                style={{
                  padding: "1rem 1rem",
                  color: "#ea3717",
                }}
                onClick={() => history("/QRCode")}
              >
                Ler QRCode
              </p>

              <p
                style={{
                  padding: "1rem 1rem",
                  color: "#ea3717",
                }}
                onClick={() => {
                  history("/Login");
                  sair();
                }}
              >
                Sair
              </p>
            </div>
            <div>
              <p
                style={{
                  padding: "1rem 1rem",
                  color: "#0091ff",
                }}
                onClick={() => {
                  window.open("https://detailland.com.br/2023/politica.php", "_blank");
                }}
              >
                Política de privacidade
              </p>
            </div>
          </div>
        </Drawer>
        <center>
          <img
            src={require("../assets/images/logo-detailland.png")}
            style={{ height: "2.5rem", width: "auto", marginBottom: "2rem", marginTop: "1rem" }}
          />
        </center>
      </div>
      <div style={{ width: "80%", marginTop: "5rem" }}>
        {login?.tipo === "expositor" && (
          <div style={{ display: "flex", alignItems: "center", color: "#fff", marginTop: "1rem" }}>
            <img src={login.imagem} style={{ height: "50px", width: "auto", marginRight: "1rem" }} />
            <p>{login.nome}</p>
          </div>
        )}
        {login?.tipo && (
          <Button
            type="primary"
            icon={<BarcodeOutlined />}
            size="large"
            style={{ marginTop: 30, width: "100%" }}
            onClick={() => {
              setScanned(false);
              history("/qrcode");
            }}
          >
            {login?.tipo === "visitante" ? "Toque aqui para ler o QRCode" : "Escanear crachá de visitante"}
          </Button>
        )}

        {login?.tipo === "visitante" ? (
          <>
            <Row align="middle" style={{ marginTop: 30, marginBottom: 15 }}>
              <Col>
                <TrophyOutlined style={{ fontSize: "2rem", color: "#fcba03", marginRight: 10 }} />
              </Col>
              <Col>
                <p style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>
                  {passaporte?.length || 0} / {expositores?.length}
                </p>
              </Col>
            </Row>
            {/* somente scroll na row de baixo */}
            <Row gutter={[16, 16]}>
              {expositores &&
                expositores.map((expositor) => (
                  <Col
                    span={8}
                    key={expositor.id}
                    style={{
                      opacity: passaporte?.find((pass) => pass.id_expositor === expositor.id_expositor) ? 1 : 0.3,
                    }}
                  >
                    <center>
                      <Image
                        src={expositor.imagem}
                        style={{
                          width: 100,
                          height: 100,
                          background: "transparent",
                          color: "#fff",
                        }}
                      />
                      <h5 style={{ color: "#fff" }}>{expositor.nome}</h5>
                    </center>
                  </Col>
                ))}
            </Row>
          </>
        ) : visitante.qtde_coletas ? (
          <div style={{ color: "#fff", border: "2px solid #fff", padding: "1rem", marginTop: "2rem" }}>
            <h3 style={{ fontWeight: "normal", marginTop: 0 }}>Coleta realizada!</h3>
            <h3 style={{ marginTop: "-1rem" }}>{visitante.nome_visitante}</h3>
            <div>
              Total de coletas: <b style={{ fontSize: "1.6rem" }}>{visitante.qtde_coletas}</b>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
