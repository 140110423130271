import React from "react";

export default React.createContext({
  getLogin: () => {},
  getPassaporte: () => {},
  sair: () => {},
  postPassaporte: () => {},
  setScanned: () => {},
  postVisitante: () => {},
  setVisitante: () => {},
  login: false,
  expositores: false,
  scanned: false,
  passaporte: false,
  visitante: false,
});
