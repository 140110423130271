import React, { useContext, useState, useEffect } from "react";
import { Image, Button, Input, Form, Modal } from "antd";
import applicationContext from "../context/applicationContext";
import { useNavigate } from "react-router-dom";
import background from "../assets/images/background.jpeg";

export default function Login() {
  const { getLogin, login } = useContext(applicationContext);
  const [cpf, setCpf] = useState("");
  const history = useNavigate();

  useEffect(() => {
    if (login.voucher || login.id_user) {
      history("/");
    }
  }, [login, history]);

  const handleLogin = () => {
    if (cpf) {
      getLogin(cpf);
    } else {
      Modal.error({
        title: "Atenção",
        content: "Preencha o campo CPF.",
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#000",
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: window.innerWidth > 800 ? "row" : "column",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <img src={require("../assets/images/logo-detailland.png")} style={{ height: "5rem", width: "auto", marginBottom: "2rem" }} />
      <div style={{ width: "80%" }}>
        <Form size="large">
          <Form.Item rules={[{ required: true, message: "Por favor, insira seu CPF!" }]}>
            <Input inputMode="numeric" placeholder="Digite seu CPF" value={cpf} onChange={(e) => setCpf(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" block onClick={handleLogin}>
              Minha área
            </Button>
          </Form.Item>
          <br />
          <br />
          <Form.Item>
            <Button
              style={{ background: "#515151", borderColor: "#515151", color: "#fff" }}
              type="default"
              block
              onClick={() => window.open("https://detailland.com.br/2024/visitante.php", "_blank")}
            >
              Cadastre-se (Acessar site)
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
