import React, { useEffect, useState } from "react";
import api from "../services/api";
import ApplicationContext from "./applicationContext";
import { Modal } from "antd";

const GlobalContext = ({ children }) => {
  const token = "DL2023*$@";
  const [login, setLogin] = useState({});
  const [expositores, setExpositores] = useState([]);
  const [passaporte, setPassaporte] = useState([]);
  const [scanned, setScanned] = useState(false);
  const [visitante, setVisitante] = useState({});

  useEffect(() => {
    const cpf = localStorage.getItem("cpf");

    if (cpf) {
      getLogin(cpf);
    }
  }, []);

  const getLogin = async (cpf) => {
    try {
      const response = await api.post("/meus_dados.php", {
        token: "DL2023*$@",
        cpf: cpf,
      });

      if (!response.data.status) {
        if (response.data.mensagem) {
          Modal.error({
            title: "Atenção",
            content: "Dados incorretos, preencha novamente.",
          });
        } else {
          Modal.error({
            title: "Atenção",
            content: "Não estamos conseguindo conexão com o servidor, verifique sua conexão de internet.",
          });
        }
      }

      if (response.status === 200) {
        setLogin(response.data);
        getExpositores();
        getPassaporte(response.data.voucher);

        // Salvar no localStorage
        localStorage.setItem("cpf", cpf);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const getExpositores = async () => {
    try {
      const response = await api.post("/expositores.php", {
        token: "DL2023*$@",
      });

      if (!response.data.status) {
        Modal.error({
          title: "Atenção",
          content: "Não estamos conseguindo conexão com o servidor, verifique sua conexão de internet.",
        });
      }

      if (response.status === 200) {
        setExpositores(response.data.expositor);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const getPassaporte = async (voucher) => {
    try {
      const response = await api.post("/passport_consulta.php", {
        token: "DL2023*$@",
        voucher: voucher || login.voucher,
      });

      // if (!response.data.status) {
      //   Modal.error({
      //     title: "Atenção",
      //     content: "Não estamos conseguindo conexão com o servidor, verifique sua conexão de internet.",
      //   });
      // }

      if (response.status === 200) {
        setPassaporte(response.data.expositor);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const postVisitante = async (qrcode) => {
    try {
      const response = await api.post("/coletar_app.php", {
        token: "DL2023*$@",
        voucher: qrcode,
        id_user: login.id_user,
        id_expositor: login.id_expositor,
      });

      if (!response.data.status) {
        Modal.error({
          title: "Atenção",
          content: "Não estamos conseguindo conexão com o servidor, verifique sua conexão de internet.",
        });
      }

      if (response.status === 200) {
        setVisitante(response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const sair = async () => {
    try {
      // Limpar o localStorage
      localStorage.removeItem("cpf");
      setLogin({});
    } catch (error) {
      console.error("Error clearing localStorage: ", error);
    }
  };

  const postPassaporte = async (qrcode) => {
    try {
      const response = await api.post("/passport_salva.php", {
        token: "DL2023*$@",
        voucher: login.voucher,
        qrcode: qrcode,
      });

      if (!response.data.status) {
        Modal.error({
          title: "Atenção",
          content: "Não estamos conseguindo conexão com o servidor, verifique sua conexão de internet.",
        });
      }

      if (response.status === 200) {
        if (response.data.id_expositor) {
          getPassaporte();
        } else {
          Modal.error({
            title: "Atenção",
            content: "QR Code Inválido, tente novamente.",
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <ApplicationContext.Provider
      value={{
        getLogin,
        login,
        expositores,
        sair,
        getPassaporte,
        postPassaporte,
        postVisitante,
        passaporte,
        scanned,
        setScanned,
        visitante,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default GlobalContext;
