import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalContext from "./context";
import Home from "./pages/Home";
import QRCode from "./pages/QRCode";
import Login from "./pages/Login";
import { ConfigProvider } from "antd";

function App() {
  return (
    <GlobalContext>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#ea3717",
            borderRadius: 0,
            // colorBgBase: "#fff",
            // colorError: "#ff4d4f",
            // colorInfo: "#1677ff",
            // colorLink: "#1677ff",
            // colorSuccess: "#52c41a",
            // colorTextBase: "#000",
            // colorWarning: "#faad14",
            // controlHeight: 32,
            // fontFamily: "Inter",
            // fontSize: 14,
            // lineType: "solid",
            // lineWidth: 1,
            // sizeStep: 4,
          },
        }}
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/qrcode" element={<QRCode />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </ConfigProvider>
    </GlobalContext>
  );
}

export default App;
